"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IN_APP_MESSAGE_TYPE = exports.LOG_LEVEL = exports.REFUND_REQUEST_STATUS = exports.BILLING_FEATURE = exports.PURCHASE_TYPE = void 0;
/**
 * @deprecated, use PRODUCT_CATEGORY
 */
var PURCHASE_TYPE;
(function (PURCHASE_TYPE) {
    /**
     * A type of SKU for in-app products.
     */
    PURCHASE_TYPE["INAPP"] = "inapp";
    /**
     * A type of SKU for subscriptions.
     */
    PURCHASE_TYPE["SUBS"] = "subs";
})(PURCHASE_TYPE || (exports.PURCHASE_TYPE = PURCHASE_TYPE = {}));
/**
 * Enum for billing features.
 * Currently, these are only relevant for Google Play Android users:
 * https://developer.android.com/reference/com/android/billingclient/api/BillingClient.FeatureType
 */
var BILLING_FEATURE;
(function (BILLING_FEATURE) {
    /**
     * Purchase/query for subscriptions.
     */
    BILLING_FEATURE[BILLING_FEATURE["SUBSCRIPTIONS"] = 0] = "SUBSCRIPTIONS";
    /**
     * Subscriptions update/replace.
     */
    BILLING_FEATURE[BILLING_FEATURE["SUBSCRIPTIONS_UPDATE"] = 1] = "SUBSCRIPTIONS_UPDATE";
    /**
     * Purchase/query for in-app items on VR.
     */
    BILLING_FEATURE[BILLING_FEATURE["IN_APP_ITEMS_ON_VR"] = 2] = "IN_APP_ITEMS_ON_VR";
    /**
     * Purchase/query for subscriptions on VR.
     */
    BILLING_FEATURE[BILLING_FEATURE["SUBSCRIPTIONS_ON_VR"] = 3] = "SUBSCRIPTIONS_ON_VR";
    /**
     * Launch a price change confirmation flow.
     */
    BILLING_FEATURE[BILLING_FEATURE["PRICE_CHANGE_CONFIRMATION"] = 4] = "PRICE_CHANGE_CONFIRMATION";
})(BILLING_FEATURE || (exports.BILLING_FEATURE = BILLING_FEATURE = {}));
var REFUND_REQUEST_STATUS;
(function (REFUND_REQUEST_STATUS) {
    /**
     * Apple has received the refund request.
     */
    REFUND_REQUEST_STATUS[REFUND_REQUEST_STATUS["SUCCESS"] = 0] = "SUCCESS";
    /**
     * User canceled submission of the refund request.
     */
    REFUND_REQUEST_STATUS[REFUND_REQUEST_STATUS["USER_CANCELLED"] = 1] = "USER_CANCELLED";
    /**
     * There was an error with the request. See message for more details.
     */
    REFUND_REQUEST_STATUS[REFUND_REQUEST_STATUS["ERROR"] = 2] = "ERROR";
})(REFUND_REQUEST_STATUS || (exports.REFUND_REQUEST_STATUS = REFUND_REQUEST_STATUS = {}));
var LOG_LEVEL;
(function (LOG_LEVEL) {
    LOG_LEVEL["VERBOSE"] = "VERBOSE";
    LOG_LEVEL["DEBUG"] = "DEBUG";
    LOG_LEVEL["INFO"] = "INFO";
    LOG_LEVEL["WARN"] = "WARN";
    LOG_LEVEL["ERROR"] = "ERROR";
})(LOG_LEVEL || (exports.LOG_LEVEL = LOG_LEVEL = {}));
/**
 * Enum for in-app message types.
 * This can be used if you disable automatic in-app message from showing automatically.
 * Then, you can pass what type of messages you want to show in the `showInAppMessages`
 * method in Purchases.
 */
var IN_APP_MESSAGE_TYPE;
(function (IN_APP_MESSAGE_TYPE) {
    // Make sure the enum values are in sync with those defined in iOS/Android
    /**
     * In-app messages to indicate there has been a billing issue charging the user.
     */
    IN_APP_MESSAGE_TYPE[IN_APP_MESSAGE_TYPE["BILLING_ISSUE"] = 0] = "BILLING_ISSUE";
    /**
     * iOS-only. This message will show if you increase the price of a subscription and
     * the user needs to opt-in to the increase.
     */
    IN_APP_MESSAGE_TYPE[IN_APP_MESSAGE_TYPE["PRICE_INCREASE_CONSENT"] = 1] = "PRICE_INCREASE_CONSENT";
    /**
     * iOS-only. StoreKit generic messages.
     */
    IN_APP_MESSAGE_TYPE[IN_APP_MESSAGE_TYPE["GENERIC"] = 2] = "GENERIC";
})(IN_APP_MESSAGE_TYPE || (exports.IN_APP_MESSAGE_TYPE = IN_APP_MESSAGE_TYPE = {}));
